<template>
  <section>
    <div>firebase test</div>
    <div>
      <div class="btn btn-primary" @click.stop.prevent="onSignInClick">
        signin
      </div>
    </div>
  </section>
</template>

<script>


/*
https://firebase.google.com/docs/web/setup#add-sdk-and-initialize
https://firebase.google.com/docs/auth/web/google-signin
https://fireship.io/lessons/google-calendar-api-with-firebase/

*/

import { gdrive } from "@/store/gapi";
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const provider = new GoogleAuthProvider();
// provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
provider.addScope('https://www.googleapis.com/auth/drive.metadata.readonly');
provider.addScope("profile email");
const cfg = {
  "client_id": "509167106205-dqqu64t7391ll6li3stvktsgband8h65.apps.googleusercontent.com",
  "scope": "profile email",
  "apiKey": "AIzaSyD3oiUFgeQLOYbk72z8ajRv3ADBVka893s",
  "firebase": {
    "apiKey": "AIzaSyD3oiUFgeQLOYbk72z8ajRv3ADBVka893s",
    "authDomain": "getshot.firebaseapp.com",
    "projectId": "getshot",
    "storageBucket": "getshot.appspot.com",
    "messagingSenderId": "509167106205",
    "appId": "1:509167106205:web:25e0e97317eb2ed6cd7c74",
    "databaseURL": "https://getshot-default-rtdb.firebaseio.com/"
  },
  "apis": {
    "drive": 'v3',
    "calendar": 'v3',
    "people": 'v1'
  }
};

const fbApp = initializeApp(cfg.firebase);
const auth = getAuth(fbApp);
export default {
  name: "FB",
  methods: {
    onSignInClick() {
      signInWithPopup(auth, provider).then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;

        google.accounts.oauth2.initTokenClient({
          client_id: cfg.client_id,
          scope: cfg.scope,
          callback: (access_token) => {
            console.log(access_token);
          },
        });
        gapi.client.setToken({ access_token: token });
        gdrive.list({
          'pageSize': 10,
          'fields': 'files(id, name)',
        }).then((response) => {
          const files = response.result.files;
          if (!files || files.length == 0) {
            document.getElementById('content').innerText = 'No files found.';
            return;
          }
          const output = files.reduce(
            (str, file) => `${str}${file.name} (${file.id})\n`,
            'Files:\n');
          console.log(output);
        })
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(error)
        // ...
      })

    }
  }

}
</script>

<style scoped>
</style>